<template>
  <v-container 
    style="
      padding: 0;
      margin-top: 122px;
    " 
    fluid
  >
    <status-bar></status-bar>

    <v-row>
      <v-col cols="12">
        <div id="background">
          <div>
            <h2 class="sub-headings font">
              A CertainFee&#8482; Story.
            </h2>
            <div class="text" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              <p class="font">
                I remember my first service business.
              </p>
              <p class="font">
                My rubber boots didn’t use laces, but I was definitely starting on a shoestring. It was mid-November when I quit my salaried job with benefits, paid vacation, and—most importantly—income security, and took the leap of faith into the lawn business.
              </p>
              <p class="font">
                Right. I started my shiny new lawn-care business exactly when grass starts to go dormant for the season. With very little time left to make money, and with only seven weekly customers, I ran classified ads for leaf-removal work. That helped me hold on for a few more weeks.
              </p>
              <p class="font">
                Once the winter came and the last of the leaves were picked up, I had to be creative and earn money any way I could think of: cleaning windows, insulating crawl spaces, hauling items, fixing toilets, even teaching Spanish at a day care a few hours a week. Then, as I was talking to a customer one day, there was a definite “Eureka!” moment. “You need to start to bill me monthly for your services,” he said. “Get your cash flow going.”
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="background-2">
          <div>
            <h2 class="sub-headings font">
              <span style="color: #83AA4B">“You need to start to</span> bill me <br />
              monthly for your services<span style="color: #83AA4B">,” he said. <br />
              “Get your cash flow going.”</span>
            </h2>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="background-3">
          <div>
            <p class="font text-2" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              Such a simple suggestion, yet it dawned on me in a way that ultimately revolutionized the way I operated. I began to understand that even the simplest service job was the kind of relationship opportunity that builds reputations and earns new customers.
            </p>
            <p class="font text-2" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              As my clients and I learned the mutual benefits of the level-pay option, seasonal uncertainty gave way to more income stability all year long. I was able to view my work as an established, successful, income-generating enter- prise—a change of perspective that delivered more and more confidence as the business grew and evolved.
            </p>
            <p class="font text-2" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              Now, I’m happy to be sharing what I learned, with CertainFee: the one simple marketing tool that helps you start simply and stay on track with all-season income. It’s the least you can do to make the most of your clientele.
            </p>
            <p class="font text-2" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              That single conversation years ago made me realize that my customers trusted me to consistently deliver a valuable service to them. I believe yours will, too.
            </p>
            <v-btn @click="toSignIn" color="#97C657" class="button">
              Get Started!
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <Footer></Footer>

  </v-container>
</template>

<script>
import StatusBar from '@/components/StatusBar.vue'
import Footer from '@/components/Footer.vue'

import { mapState } from 'vuex'
import router from '../router'

export default {
  name: 'Home',
  data() {
    return {}
  },
  components: {
    StatusBar,
    Footer,
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    loggedIn() {
      this.$store.dispatch('loggedIn')
    },
    toSignIn() {
      router.push({ name: 'SignIn' })
    }
  },
  mounted() {
    window.scrollTo(0,0)
    this.$store.dispatch('loggedIn')
  },
}
</script>

<style scoped>
.font {
  font-family: "NunitoSans-Light";
}

.button {
  margin-top: 43px;
  margin-bottom: 7vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  color: whitesmoke;
}

.lr-padding {
  padding-left: 20%;
  padding-right: 20%;
}

.text {
  padding-top: 43px;
  font-size: 12pt;
}

.text-2 {
  font-size: 12pt;
}

.sub-headings {
  font-size: 27pt;
  line-height: 1em;
  text-align: center;
}

#background {
   /* !important; */
  /* height: 41vw;
  width: 100vw; */
  padding: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #77787B;
}

#background-2 {
  background: url('../assets/Grass.jpg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-position: fixed !important;
   /* !important; */
  background-size: cover !important;
  padding: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
}

#background-3 {
   /* !important; */
  padding: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #77787B;
}
</style>
